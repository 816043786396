<template>
    <div class="edit-email">
        <!--
        <b-modal
          v-model="modalOpen"
          centered
          hide-footer
          class="edit-email__modal"
          id="edit-email-modal"
          header-close-variant="primary"
          :title="$t('user.editEmail.title')">
            <p v-show="!loading" class="edit-email__message" v-html="
              $t('user.editEmail.messages.codeSentTo', { email: `<strong>${amplify.userEmail}</strong>`})
              "/>
              
            <v-row class="wallet-row">
              <v-col cols="12" sm="6">
                <InputComponent
                  v-model="amplify.confirmationCode"
                  :label="$t('user.amplify.Confirmation Code')"
                  :placeholder="$t('user.amplify.Code')"
                  :error="$v.amplify.confirmationCode.$invalid ? amplifyMessage('code') : ''"
                  inputType="text"
                />
                <div>
                  <span>{{$t('user.amplify.Lost your code? ')}}</span>
                  <span><a href="#" :title="$t('user.amplify.Resend Code')" @click.prevent="resendCode">{{$t('user.amplify.Resend Code')}}</a></span>
                  <b-spinner small v-if="loading" class="spinner" variant="primary" label="loading"></b-spinner>
                </div>
              </v-col>
            </v-row>
            <span v-if="errorMessage" class="color-text-error">{{errorMessage}}</span>
            <div class="edit-email__modal-footer d-flex flex-row btn-wrapper">
              <a href="#" class="cancel-btn-wrapper">
                <button class="cancel-btn" @click.prevent="closeModal">
                  {{$t('cancelBtn')}}
                </button>
              </a>
              <span class="d-flex flex-grow"/>
              
              <button
                class="btn align-right"
                :disabled="$v.amplify.confirmationCode.$invalid"
                @click="verifyCode">
                  <b-spinner small v-if="loading" class="spinner" variant="primary" label="loading"></b-spinner>
                  <strong v-else>
                  {{$t('user.amplify.Confirm')}}
                  </strong>
                </button>
            </div>
        </b-modal>
        -->
        <InputComponent
          disabled
          :readonly="readonly"
          :filled="filled"
          v-model="amplify.userEmail"
          :label="$t('user.amplify.Email')"
          :placeholder="$t('user.amplify.Enter your email')"
          :error="$v.amplify.userEmail.$invalid ? amplifyMessage('email') : ''"
          :onChange="onEmailUpdate"
          inputType="text"
        >
          <template v-slot:label >
            <!-- update email on input fill -->
            <div v-if="$v.amplify.userEmail.$dirty && !$v.amplify.userEmail.$invalid" class="d-flex">
              <a href="#" :title="$t('user.editEmail.emailUpdate')" @click.prevent="onUpdate" class="exporo-input-label label--update">
                <div class="tooltip-btn exporo-input-label icon">!</div>
                {{ $t('user.editEmail.emailUpdate') }}
              </a>
            </div>
            <!-- verified email -->
            <div v-if="!!user.email_verified && !$v.amplify.userEmail.$dirty" class="d-flex">
              <div class="tooltip-btn exporo-input-label icon icon--success">&#10003;</div>
              <span class="exporo-input-label label--success">{{ $t('user.editEmail.emailVerified')}}</span>
            </div>
            <!-- unverified email -->
            <div v-if="!user.email_verified && !$v.amplify.userEmail.$dirty" class="d-flex">
              <b-spinner small v-if="loading" class="spinner" label="loading"></b-spinner>
              <a href="#" @click.prevent="openModal" class="exporo-input-label label--error">
                <div class="tooltip-btn exporo-input-label icon icon--error">!</div>
                {{ $t('user.editEmail.emailUnverified')}}
              </a>
            </div>
          </template>
        </InputComponent>
        <span v-if="invalidUpdateMessage" class="color-text-error">{{invalidUpdateMessage}}</span>
    </div>
</template>

<script>
  import { Auth } from 'aws-amplify';
  import { mapActions, mapGetters } from 'vuex';
  import InputComponent from '@/components/reusable/Input/Input.vue';
  import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators';

  export default {
    name: 'edit-email',
    components: {
      InputComponent,
    },
    data() {
      return {
        amplify:{
          confirmationCode: null,
          userEmail: null,
        },
        modalOpen: false,
        confirmingCode: false,
        emailStatus: null,
        loading: false,
        updateError: null,
        errorMessage: null,
        invalidUpdateMessage: null,
      };
    },
    props: {
      readonly: {
        type: Boolean,
        default: false,
      },
      filled: {
        type: Boolean,
        default: false,
      },
    },
    async mounted(){
      this.amplify.userEmail = this.user.email;
    },

    methods: {
      ...mapActions(['setVerifiedEmail']),
      onEmailUpdate(){
        this.$v.amplify.userEmail.$touch();
      },
      async updateUserEmail(){
        this.clearErrors();
        this.loading = true;

        try {
          const user = await Auth.currentAuthenticatedUser();

          const result = await Auth.updateUserAttributes(
            user,
            {
              email: this.amplify.userEmail.toLowerCase(),
            },
            {
              pathname: window.location.pathname,
              host: window.location.host,
              locale: this.$i18n.language,
          });
          this.setVerifiedEmail(false);
          this.openModal();
          this.$forceUpdate();
        } catch (error) {
          this.invalidUpdateMessage = this.$t(`user.editEmail.errorCodes.${error.code}`);
          console.log('updateUserEmail',error);
        }

        this.loading = false;
      },
      async resendCode(){
        this.clearErrors();
        this.loading = true;

        try {
          const user = await Auth.currentAuthenticatedUser();
          const result = await Auth.verifyUserAttribute(user,
            'email',
            {
              pathname: window.location.pathname,
              host: window.location.host,
              locale: this.$i18n.language,
            });
          console.info('resendCode', result);
          this.errorMessage = null;
          this.$forceUpdate();
        } catch (error) {
          this.errorMessage = this.$t(`user.editEmail.errorCodes.${error.code}`);
          console.log('resendCode',error);
        }
        
        this.loading = false;
      },
      async verifyCode(){
        this.clearErrors();
        this.loading = true;

        try {
          await Auth.verifyCurrentUserAttributeSubmit('email', this.amplify.confirmationCode);
          this.setVerifiedEmail(true);
          this.$v.amplify.userEmail.$reset();
          this.amplify.confirmationCode = null;
          this.$v.amplify.confirmationCode.$reset();
          
          this.$forceUpdate();
          this.closeModal();
        } catch (error) {
          this.errorMessage = this.$t('user.editEmail.errorCodes.CodeMismatchException');
          console.log('verifyCode',error);
        }

        this.loading = false;
      },
      onUpdate(){
        this.updateUserEmail();
      },
      amplifyMessage(field){
        if(field === 'email'){
          if(!this.$v.amplify.userEmail.email){
            return this.$t('user.amplify.Invalid email format');
          };
          if(!this.$v.amplify.userEmail.required) {
            return this.$t('user.amplify.Username cannot be empty');
          }
        }
        if(field === 'code'){
          if(!this.$v.amplify.confirmationCode.required) {
            return this.$t('user.amplify.Username cannot be empty');
          }
        }

        return this.$t('metaData.fields.error.default');
      },
      clearErrors(){
        this.errorMessage = null;
        this.invalidUpdateMessage = null;
      },
      openModal(){
        this.modalOpen = true;
      },
      closeModal(){        
        this.modalOpen = false;
      },
    },
    computed:{
      ...mapGetters(['user']),
      canConfirmCode() {
        return !this.$v.amplify.confirmationCode.$invalid;
      },
    },
    validations: {
      amplify: {
        userEmail: {
          required,
          email,
        },
        confirmationCode: {
          required,
          numeric,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
      },
    },
  };
</script>

<style scoped lang="scss">
@import "@/style/base.scss";
@import "@/style/usermeta.scss";

.edit-email{
  &__message{
    font-size: font-size('small');
    margin-bottom:10px;
  }
  &--status{
    text-transform: uppercase;
  }
  .label {
    &--error{
      text-transform: uppercase;
      color: color("text", "error") !important;
    }
    &--success{
      color: color("text", "success") !important;
      text-transform: uppercase;
      pointer-events: none;
    }
    &--update{
      text-transform: uppercase;
    }
  }
  
  .icon{
    margin-right: 5px;
    &--error{
      background: color("text", "error") !important;
    }
    &--success{
      background: color("text", "success") !important;
    }   
  }
}

.text-primary{
  color: color("text", "primary") !important;
}

.spinner{
  border: 3px solid color("text", "primary");
  border-left: 3px solid color("text", "light");
  align-self: center;
    vertical-align: middle;
    margin: 0 10px;
    padding: 0;
}
.btn {
  .spinner{
    border: 3px solid color("text", "light");
    border-left: 3px solid transparent;
    margin: 0 10px;
  }
}

</style>

