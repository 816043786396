<template>
  <div>
    <div class="select-title-wrapper">
      <div v-if="!!tooltip" class="tooltip-btn select-label" v-tooltip="tooltip">i</div>
    </div>
    <div class="select-section" :class="{ 'error-select-section': isError() }">
      <div v-if="!filled" class="subtitle-2 text--primary mb-2">{{ label }}</div>
      <v-select
        :error="!!error"
        :outlined="!readonly && !filled"
        :readonly="readonly"
        :dense="readonly"
        :solo="readonly"
        :flat="readonly"
        :disabled="!!readonly ? false : disabled"
        :class="{ readonly: readonly }"
        :value="value"
        @input="onSave"
        :items="optionsList"
        hide-details="auto"
        :rules="[() => !isError()]"
        :messages="[error]"
        :clearable="clearable"
        :placeholder="placeholder"
        :filled="filled"
        :label="filled && value ? label : null"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',

  props: [
    'label',
    'value',
    'onSelect',
    'optionsList',
    'valueKey',
    'error',
    'valueLabel',
    'placeholder',
    'tooltip',
    'nextBtnClicked',
    'disabled',
    'readonly',
    'clearable',
    'filled',
  ],
  methods: {
    onSave(value) {
      this.$emit('input', value);
      if (this.onSelect) {
        this.onSelect();
      }
    },
    isError() {
      return (!!this.value && !!this.error) || (!!this.nextBtnClicked && !!this.error);
    },
  },
};
</script>

<style lang="scss">
.select-section {
  .readonly {
    cursor: default;
    pointer-events: none;
    .v-input__slot {
      padding: 0 !important;
    }
    .v-input__icon {
      opacity: 0;
    }
  }
}
</style>